import React, { useContext, useEffect, useState } from 'react'
import { Gen2Context } from '../contexts/Gen2Context'
import * as styles from './adopt-cub.module.css'

export default function AdoptCub() {

	const { contract, adoptCubs, adoptedCubs, maxAdoptions, maxQuantity } = useContext(Gen2Context) || {}

	const [controlsDisabled, setControlsDisabled] = useState(false)
	const [quantity, setQuantity] = useState<number>(2)
	const [quantities, setQuantities] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])

	async function quantityChanged(e: any) {
		console.log(`quantityChanged: ${e.target.value}`)
		setQuantity(e.target.value)
	}

	async function adoptClicked() {
		setControlsDisabled(true)
		try {
			await adoptCubs(quantity)
		} catch (error) {
			// TODO: Notify the user with a Toast message
			if (error.code == 4001 || error.code == 4100) {
				console.log(`Adoption not authorized by user`)
			} else {
				console.error(error)
			}
		}
		setControlsDisabled(false)
	}

	useEffect(() => {
		setControlsDisabled(contract === null || contract === undefined)
	}, [contract])

	useEffect(() => {
		if (quantities.length > 0 && maxQuantity == quantities[quantities.length-1]) return
		let array = Array.from(Array(maxQuantity).keys()).map(x => x + 1)
		console.log(`Updating max quantity: ${maxQuantity} => ${array}`)
		setQuantities(array)
	}, [maxQuantity])

	return (
		<>
			<p className={styles.numRendered}>{adoptedCubs}/{maxAdoptions} Cubs adopted</p>
			<div className={styles.dropdown}>
				<div className={styles.select}>
					<select disabled={controlsDisabled} onChange={quantityChanged} defaultValue={quantity}>
						{quantities.map((value) =>
							<option key={value} value={value}>{value}</option>
						)}
					</select>
				</div>
			</div>
			<button className="initiate" disabled={controlsDisabled} onClick={adoptClicked}><h4>Adopt</h4></button>
		</>
	)
}

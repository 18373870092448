import Layout from '../components/layout'
import React from 'react'
import { Gen2Provider } from '../contexts/Gen2Context'
import AdoptCub from '../components/adopt-cub'

export default function IndexPage({ data }) {

	return (
        <Layout prompt="Please Select The Number of Hyper-Realistic Cubs you wish to generate/adopt">
			<Gen2Provider>
				<AdoptCub/>
			</Gen2Provider>
        </Layout>
    )
}
